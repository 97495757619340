/* =================
   Spinners
==================== */

.sk-rotating-plane,.sk-double-bounce .sk-child,.sk-wave .sk-rect,.sk-wandering-cubes .sk-cube,.sk-spinner-pulse,
.sk-chasing-dots .sk-child,.sk-three-bounce .sk-child,.sk-circle .sk-child:before ,.sk-cube-grid .sk-cube ,
.sk-fading-circle .sk-circle:before,.sk-folding-cube .sk-cube:before{
  background-color: $primary;
}
.sk-fading-circle {
  .sk-circle {
    margin-top: 0;
  }
}